.ProgramSelector {
  .program-list {
    li {
      display: block;
    }

    a {
      display: block;
      color: #fff;
      text-decoration: none;
      padding: 1.8rem 0 1.8rem 1.5rem;
      border-top: 1px solid #6f7a8d;
      border-collapse: collapse;
      transition: all 0.15s ease-in;

      &:hover,
      &.active {
        background-color: #586374;
      }
    }

    li.active > a {
      background-color: #586374;
    }
  }
}
