.overdue-card{
    border: 1px solid white;
    padding: 15px;
    background-color: white;
    border-radius: 5px;
    color:#a7a5b2
}


.delinquency_result_main{
    .sub-div-delinquency{
        color:#60576b;
        padding: 0px 20px 20px 20px;
        margin-top: 10px;
      }
      .rupee-amt{
        font-weight: bold;
        color:red;
        font-size: 23px;
        margin-top:-20px;
      }
      .cust_tbleBody td{
        background-color: white;
        padding: 15px 10px 15px 5px;
      }
      .cust_tbleHead th{
        color:#8b879c;
        font-weight: bold;
        padding: 10px 15px 10px 5px;
        background-color: #f1f1f1;
        z-index: 0;
      }
      .td-cell{
        font-size: 13.5px;
        font-weight: 600;
        color:#aea8ba
      }
      .th-cell{
      text-transform: capitalize;
      font-size: 13.5px;
      }
      .history{
        color:#8b879c;
        margin: 15px 0px 15px 0px;
      }
      .delinquency-heading{
        font-size: 18px;
        font-weight: 600;
        color:#60576b;
        padding: 0px 20px 20px 20px;
    }
  }

  

  