.program-name{
    color: #bbc2d0;
    font-size: 15px;
    font-weight: 700;
}
.program-info-div{
    display: flex;
    justify-content: space-between;
}
.pg-info-clr{
    color: #bbc2d0;
    font-size: 13px;
    font-weight:700;
}