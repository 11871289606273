.CustomerSearchResults {
  max-height: 65vh;
  overflow-y: auto;
}
.CustomerSearch {
  position: relative;
}
.disclaimer-container {
  top: -9px;
  right: 16px;
  display: block;
}
.disclaimer-container a span.pismo-link {
  display: block;
}
.disclaimer-container a:hover {
  text-decoration: underline;
}
