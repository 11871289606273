$pismo-blue: #3cb4e0;
$pismo-navy-blue: #000080;
$pismo-bright-blue: #64c8ec;
$pismo-brighter-blue: #91daf5;
$pismo-darker-blue: #202832;
$pismo-dark-blue: #2c3644;
$pismo-near-dark-blue: rgba(44, 54, 68, 0.5);
$pismo-grayish-blue: #6f7a8d;
$pismo-dark-grayish-blue: #586374;
$pismo-smoke: #6e7a8c;
$pismo-darker-grayish-blue: #424c5c;
$pismo-orange: #ec8246;
$pismo-light-orange: lighten(#ec8246, 1);
$pismo-redish-orange: #ff9800;
$pismo-light-yellow: lighten(#f5a623, 1);
$pismo-yellow: #f5a623;
$pismo-dark-yellow: #ff9800;
$pismo-pink: #ec3853;
$pismo-dark-pink: #cc3646;
$pismo-darker-pink: #cc3646;
$pismo-silver: #e1e5ed;
$pismo-dark-gray: #768092;
$pismo-lighter-gray: #eceef2;
$pismo-light-gray: rgba(236, 238, 242, 0.5);
$pismo-mid-gray: #a3acbc;
$pismo-gray: #c1c7d4;
$pismo-near-black: #151a21;
$pismo-near-white: #fafafa;
$pismo-light-silver: #8692a6;
$pismo-lighter-silver: #d7dae0;
$pismo-darker: #202732;
$pismo-red: #f44336;
$pismo-warning: lighten(#9f6000, 15);
