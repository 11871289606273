@import '../../App/colors.scss';

.menu-vertical {
  height: 4.2rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 1;
  text-align: center;
  background-color: #1a1a22;
  transition: width 0.5s;
  display: flex;
  justify-content: space-around;
  flex-direction: column;



  .toll-free-class{
    color: rgb(86, 85, 98);
    font-size: 13px;
    font-weight: 700;
    text-align: left;
    margin-left: 10px;
    padding: 2px;
  }

  .link-btn-container {
    display: flex;
    // align-items: center;
    justify-content: center;

    &:hover {
      background-color: #000;
    }

    &:focus-within {
      border: 2px solid #1897f3;
    }

    .link-btn {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    .link-btn-label {
      color: #ebeaf0;
    }

    div.active {
      color: #64bcfc;
    }

    &.cards {
      display: none;
    }
  }

  .menu-header {
    display: flex;
    align-items: center;
    background-color: #000;
    width: 100%;
    height: 60px;
    padding-left: 20px;

    .menu-header-label {
      font-size: 18px;
      color: #838192;
      margin-left: 29px;
    }
  }

  .list {
    overflow-y: auto;
    flex-grow: 2;
  }

  .menu-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    bottom: 0;
    border-top: solid 1px #525061;
    width: 100%;
    height: 60px;
    transition: width 0.5s;
  }

  .menu-footer .menu-footer-label {
    font-size: 14px;
    color: #838192;
  }
  .menu-vertical-expand-btn {
    position: absolute;
    top: 48.45%;
    right: -11px;
    z-index: 6;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    background: #dbdae2;
    display: flex;
    align-items: center;
  }

  .menu-vertical-expand-btn img {
    position: relative;
  }

  .menu-vertical-expand-btn .icon-cheron-right {
    left: 5px;
  }

  .menu-vertical-expand-btn .icon-cheron-left {
    left: 3px;
  }

  .link-btn-hide {
    display: none;
  }
}

@media screen and (min-width: 24rem) {
  .menu-vertical {
    .link-btn-container {
      .link-btn-label {
        font-size: 0.75rem;
      }
    }
  }
}

@media screen and (min-width: 30rem) {
  .menu-vertical {
    width: 4.5rem;
    position: relative;
    bottom: initial;
    z-index: initial;

    .link-btn-container {
      &.mobileSummary {
        display: none;
      }

      &.cards {
        display: inline-block;
      }
    }
  }

  .menu-vertical-grow {
    width: 15rem;
  }

  .menu-vertical-grow .list .recoil {
    padding-left: 20px;
  }

  .menu-vertical-grow .list .link-btn-container {
    width: 100%;
    display: inline-block;
  }

  .menu-vertical-grow .list .link-btn-container .link-btn {
    display: flex;
    align-items: center;

    div.active {
      color: #64bcfc;
    }
  }

  .menu-vertical-grow .list .link-btn-container .link-btn-label {
    font-weight: 700;
    font-size: 18px;
    color: #ebeaf0;
    margin-left: 29px;
    margin-bottom: 3px;
  }

  .menu-vertical-grow .menu-footer {
    padding-left: 20px;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 30rem) {
  .menu-vertical {
    .menu-header {
      display: none;
    }

    .menu-footer {
      display: none;
    }

    .list {
      display: flex;
      justify-content: space-around;
      overflow-y: auto;
    }

    .link-btn-container {
      &.cards {
        display: inline-block;
      }

    }
  }
}
