img {
  display: revert;
}

ol,
ul {
  margin: revert;
}

svg {
  display: revert;
}

svg {
  box-sizing: revert;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: revert;
  font-size: revert;
  font-weight: revert;
  display: revert;
}
