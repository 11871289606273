@import '../../../App/colors.scss';

.Checkbox {
  .toggle {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden;
  }

  label > span {
    margin-left: 3rem;
  }

  input.toggle-round + label > span {
    color: #a3acbc;
    position: absolute;
    top: -2px;
  }

  input.toggle-round:checked + label > span {
    color: #2c3644;
  }

  input.toggle-round:disabled{
    color: #20da32;
  }

  .toggle + label {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    user-select: none;
  }

  input.toggle-round + label {
    padding: 2px;
    width: 40px;
    height: 9px;
    background-color: #dddddd;
    border-radius: 20px;
  }

  input.toggle-round + label:before,
  input.toggle-round + label:after {
    display: block;
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 1px;
    content: '';
  }

  input.toggle-round + label:before {
    right: 1px;
    background-color: #f1f1f1;
    border-radius: 20px;
    transition: background 0.4s;
  }

  input.toggle-round + label:after {
    width: 22px;
    height: 22px;
    background-color: #fff;
    border-radius: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transition: margin 0.4s;
    top: -7px;
  }


  input.toggle-round:disabled + label:after {
    background-color: #e1e5ed;
  }


  input.toggle-round:checked + label:before {
    background-color: $pismo-near-black;
  }

  input.toggle-round:checked + label:after {
    margin-left: 22px;
    background-color: $pismo-grayish-blue;
  }

  input.toggle-round:disabled + label:before {
    background-color: #e1e5ed;
    cursor: not-allowed;
  }

  input.toggle-round:disabled + label:after {
    background-color: #e1e5ed ;
    cursor: not-allowed;
  }
}
