.timeline_result_main {
  position: relative;
  height: calc(100vh - 250px);
}

.timeline_result_second {
  overflow: visible;
  height: 0px;
  width: 0px;
}
.timeline_result_scroll {
  position: relative;
  height: 412px;
  width: 768px;
  overflow: auto;
  will-change: transform;
  direction: ltr;
}
.timeline_result_ul {
  height: 3362px;
  width: 100%;
}

.timeline_result_li {
  left: 0px;
  top: 0px;
  width: 100%;
}
.cblack {
  color: black !important;
}

.SR_mainGrid {
  padding: 20px;
}
.SR_datesearch {
  height: 40px;
  width: 100%;
  background-color: #e4e4e4;
  text-align: center;
  padding-top: 10px;
  letter-spacing: 1px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 400;
}
.SR_dateTitle {
  color: #5589ae;
  font-size: 12px;
  font-weight: 600;
}
.SR_rightborder {
  border-right: 1px solid rgb(186, 176, 176);
}
.SR_searchbtn {
  background-color: #fe6f61 !important;
  color: whitesmoke !important;
  width: 75px;
}
.SR_searchbtn:hover {
  background-color: #fa9583 !important;
}
.box-shadow {
  box-shadow: 0px 4px 25px 5px rgba(36, 86, 113, 0.2);
}

.SR_thead {
  background-color: #214d72 !important;
}
.SR_thead tr th {
  color: white !important;
  font-size: 14px;
  font-weight: 600;
}
.paper {
  background-color: white;
  min-height: 90vh !important;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
}
.SR_pg button {
  width: auto !important;
}

.SR_Toggle .MuiSwitch-switchBase {
  color: #fe6f61;
}
.SR_Toggle .MuiSwitch-colorSecondary.Mui-checked {
  color: #fe6f61;
}
.txt-white {
  color: white;
}
.timeline_clear_btn {
  background-color: #fe6f61 !important;
}
