.Toast {
  // position: relative;
  width: 99%;
  height: 3.5rem;
  left: 0.5%;
  padding-left: 8px;
  padding-right: 10px;

  @media screen and (min-width: 30rem) {
    width: 38rem;
    margin: auto;
    right: 0;
    left: 0;
  }

  .Icon {
    display: inline-block;
    vertical-align: middle;
    line-height: 3.3rem;
    width: 10%;
    height: 100%;

    @media screen and (min-width: 30rem) {
      width: 2.5rem;
      line-height: 3.2rem;
    }
  }

  .Message {
    display: inline-block;
    vertical-align: middle;
    width: 85%;
    padding-left: 6px;

    @media screen and (min-width: 30rem) {
      width: 86%;
      padding-left: 8px;
    }
  }
}
