.transfer_resume_image {
  width: 80%;
  margin: 15px 0;
}

.transfer_amount {
  font-size: 1.2em;
  text-align: center;
  line-height: 1.3;
}

.transfer_value {
  font-size: 2em;
}
