.CustomerSearchResults {
  max-height: 65vh;
  overflow-y: auto;
}
.CustomerSearch {
  position: relative;
}
.disclaimer-card-container {
  top: -9px;
  right: 16px;
  display: block;
}
