:root {
  .CL_000CUB {
    --primaryColor: #ed008c;
    --primaryLightColor: #ff0097;
    --ribbonTopColor: #FF0097;
    --topRibbonColor: #000080;
    --bottomRibbonColor: #df017c;
  }
  .CL_00UTKB {
    --primaryColor: #45136d;
    --primaryLightColor: #813ebf;
    --ribbonTopColor: #8E00FF;
    --topRibbonColor: #45136d;
    --bottomRibbonColor: #45136d;
  }
}
